

import { getApps, initializeApp } from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

export default class FirebaseConfig {
    firebaseConfig = {
        apiKey: 'AIzaSyBhiVlx_6bzosqDduxideqFDSoB4LVn7kA',
        authDomain: 'virtual-school-of-english-prod.firebaseapp.com',
        databaseURL: 'https://virtual-school-of-english-prod.firebaseio.com',
        projectId: 'virtual-school-of-english-prod',
        storageBucket: 'virtual-school-of-english-prod.appspot.com',
        messagingSenderId: '1064492156677',
        appId: '1:1064492156677:web:e49fe89bf99373c58a196c',
        measurementId: 'G-942LBK8DWK',
    };

    init() {
        if (getApps().length === 0) {
            initializeApp(this.firebaseConfig);
        }
    }
}
