import '../styles/globals.css'
import Head from 'next/head'
import React from 'react'
import FirebaseConfig from 'utils/firebaseConfig';
import { styled } from '@stitches/react';
import { getAuth, signInWithCustomToken } from 'firebase/auth';

interface IFrameParentProp {
  isLoading: boolean
  errorMessage: string
  jwtToken: string
  ns: string
}

const defaultParentProp: IFrameParentProp = {
  isLoading: true,
  jwtToken: null,
  ns: null,
  errorMessage: null
}

function MyApp({ Component, pageProps }: any) {
  const firebase = new FirebaseConfig()
  firebase.init()

  const [parentProp, setParentProp] = React.useState(defaultParentProp);

  React.useEffect(() => {

    const parentCall = (event) => {
      if (event.data && event.data.type === 'wb_config') {
        const parentResult = composeParentResult(event.data)

        const auth = getAuth();
        var user = auth.currentUser;
        if (user) {
          setParentProp(parentResult)
        } else {
          signInWithCustomToken(auth, event.data.firebaseToken)
            .then((userCredential) => {
              setParentProp(parentResult)
            })
            .catch((error) => {
              parentProp.errorMessage = ':( Whiteboard couldn\'t load. Try refreshing the page or get in touch at hello@virtualschoolofenglish.com'
              setParentProp(parentResult)
            });
        }
      }
    }

    window.addEventListener('message', parentCall)
    window.parent.postMessage({ 'type': 'wb_iframe' }, "*")

    return () => {
      window.removeEventListener('message', parentCall)
    };
  }, []);

  // add the params from parent to the rest of the app
  if (!parentProp.isLoading && parentProp.jwtToken && parentProp.ns) {
    pageProps['jwtToken'] = parentProp.jwtToken
    pageProps['ns'] = parentProp.ns
  }

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no"
        />
        <link rel="manifest" href="/manifest.json" />
      </Head>
      {(parentProp.isLoading || parentProp.errorMessage != null) ?
        <PreparingScreen>{parentProp.errorMessage != null ? parentProp.errorMessage : ''}</PreparingScreen>
        :
        <Component {...pageProps} />
      }
    </>
  )
}

export default MyApp

const PreparingScreen = styled('div', {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

function composeParentResult(data: any): IFrameParentProp {
  return {
    isLoading: false,
    jwtToken: data.jwtToken,
    ns: data.ns,
    errorMessage: null
  }
}

